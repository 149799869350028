.modal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #000000b1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.modal_content{
    background-color: #fff;
    border-radius: 3px;
    max-height: 95vh;
    overflow-y: auto;
}