.copy{
    /* border: 1px solid black; */
    margin: 10px 20px ;

}
.close{
    /* border: 1px solid black; */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.close SVG{
    height: 20px;
    width: 20px;
    cursor: pointer;
    color: #565555;
}

.a2z{
    font-size: .9rem;
}

