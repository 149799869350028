/* .blogTitle-a{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-bottom: 0px !important;
} */

.blog-a a{
    color: #3366cc;
    cursor: pointer; 
}
.blog-a a:hover{
    text-decoration: underline;
}



.blogContent-a{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-bottom: 0px !important;
    transition: -webkit-line-clamp .6s ease;
}
#readMore-a{
    cursor: pointer;
}
.readMore{
    display: inline-block;
    cursor: pointer;
}

.pagination-a{
    /* border: 3px solid black; */
    padding-left: 70px;
}

.blogImg-a{
    aspect-ratio: 3/2;
}

.blogHeadingSearch{
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
}

.input-groupSearch{
    display: flex;
}