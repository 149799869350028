.svg{
    /* border: 1px solid black; */
    padding-right: 8px;
    position: absolute;
    left: 25px;
    top: 10px;
}



.svg SVG{
    color: #09b15a;
    height: 25px;
    width: 25px;
}

.hello{
    border: 1px solid black;
}

.rel{
    position: relative;
}


@media only screen and (min-width: 435px) and (max-width: 768px ){


    .faq{
        padding-top: 10px;
    }
  
    .body_zz{
            font-size: 15px;
    }

    .svg{
        top: 12px;
    }
}




@media only screen and (min-width: 375px) and (max-width: 640px ){
    
    
    .svg SVG{
        height: 20px;
        width: 20px;
    }
    
    .faq{
        padding-top: 10px;
    }
    
    .heading_zz{
        font-size: 15px;
    }
  
    .body_zz{
        font-size: 14px;
    }
    
    .svg{
        top: 5px;
    }
}

@media only screen and (min-width: 200px) and (max-width: 320px ){
    .svg SVG{
        height: 18px;
        width: 18px;
    }
    
    .faq{
        padding-top: 10px;
    }
    
    .heading_zz{
        font-size: 12px;
    }
  
    .body_zz{
        font-size: 10px;
    }
    
    .svg{
        top: 5px;
    }
}



