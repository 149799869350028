/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
} */

.spotrackLogo-a {
  max-height: 45px;
  /* border: 1px solid black; */
}

.navbar-a {
  height: 90px;
  /* border: 1px solid black; */
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.container-a {
  /* max-width: 1100px; */
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  /* border: 1px solid black; */
}




.logo-a {
  max-height: 45px;
  margin-right: 6px;
  /* border: 1px solid black; */
}

.menu-icon-a {
  display: none;
  text-align: center;
  /* border: 1px solid black; */
  /* padding: 3px 5px 3px 5px ; */
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: #0a45bbd1;
}

.menu-icon-a SVG{
    height: 30px;
    width: 30px;
}

.nav-elements-a {
  /* border: 2px solid green; */
  /* display: flex; */
  /* align-items: center; */
}
.btn-a {
  outline: none;
  border: none;
  background-color: #09b15a;
  color: white;
  padding: 6px 18px;
  border-radius: 5px;
}

.btn-a:hover{
    background-color: #0d6efd;
}

.nav-elements-a ul {
    display: flex;
    margin: 0;
    align-items: center;
    list-style-type: none;
    gap: 25px;
    /* border: 1px solid red; */
  /* justify-content: space-between; */
}

/* .nav-elements-a ul li:not(:last-child) {
    margin-right: 30px;
    border: 1px solid red;
} */

.nav-elements-a ul a {
  font-weight:900;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  scale: .95;
}

.nav-elements-a ul a:hover {
   scale: 1;
   color: #0d6efd;
   /* font-weight: 400; */
}

.active-nav{
   color: #0d6efd;
}

.nav-elements-a ul a.active-a {
  color: #fff;
  font-weight: 300;
  position: relative;
}

.nav-elements-a ul a.active-a::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  /* background-color: #574c4c; */
}
.close-a{
    display: none;
}

@media (max-width: 960px) {
    .menu-icon-a {
        display: block;
        cursor: pointer;
      }
    
      .nav-elements-a {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        background-color: rgba(14, 29, 52, 0.9);
        width: 0px;
        height: calc(100vh);
        transition: all 0.3s ease-in;
        overflow: auto;
        /* border: 1px solid red; */
      }
    
      .nav-elements-a.active-a {
        width: 300px;
      }

      .close-a{
        display: flex;
        /* border: 1px solid white; */
        justify-content: end;
        padding: 20px;
    }
    
    .close-a SVG{
        cursor: pointer;
        height: 30px;
        width: 30px;
      }
    
      .nav-elements-a ul {
        display: flex;
        flex-direction: column;
        align-items:start;
        gap: 0;
    }
    
    .nav-elements-a ul a{

      scale: 1;
    }

    .nav-elements-a ul a:hover{
      color: #fff;
    }

    .nav-elements-a ul li:hover {
      /* scale: 1; */
      /* border-bottom:1px solid #0d6efd;  */
      /* font-weight: 400; */
   }
    
    .nav-elements-a ul a{
        color: #fff;
    }
    
    .btn-a{
        width: 265px;
        text-align: start;
    }
    
    .nav-elements-a ul li {
        margin-right: unset;
        margin-top: 22px;
        width: 265px;
        border-bottom: 1px solid #fff;
        /* border: 1px solid black; */
      }

      

      .nav-elements-a ul .li-last-a{
        border-bottom: none;
      }
    
}

@media (max-width: 600px) {
  .menu-icon-a {
    display: block;
    cursor: pointer;
    /* border: 1px solid black; */
  }

  .nav-elements-a {
    position:absolute;
    right: 0;
    top: 0;
    color: #fff;
    /* border: 2px solid red; */
    background-color: #0e1d34e6;
    width: 0px;
    height: calc(100vh);
    transition: all 0.3s ease-in;
    overflow: auto;
  }

  .nav-elements-a.active-a {
    width: 250px;
  }

  .nav-elements-a ul {
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    gap: 0;
}

.nav-elements-a ul a{
    color: #fff;
}

.btn-a{
    width: 218px;
    text-align: start;
}

.nav-elements-a ul li {
    margin-right: unset;
    margin-top: 22px;
    border-bottom: 1px solid #fff;
    width: 218px;
  }
  .nav-elements-a ul .li-last-a{
    border-bottom: none;
  }
}


@media (min-width: 765px) and (max-width: 1024px) {
  .container-a{
    padding: 20px 40px;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .container-a{
    padding: 20px 70px;
  }
}

@media (min-width: 1440px) {
  .container-a{
    padding: 20px 70px;
    max-width: 1440px;
  }
}

