.con {
  display: flex;
}

/* .uday {
  display: flex;
  flex-wrap: ;

  height: 100px;
  align-items: center;
  width: fit-content;
} */

.slider {
  background: white;
  display: flex;
  align-items: center;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: auto;
  margin-top: 30px ;
  overflow: hidden;
  position: relative;
  width: 100%; 
      /* border: 1px solid black; */
}

.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  position: absolute;
  width: 100px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slide-track {
  display: flex;
  width: calc(250px * 8);
  animation: scroll 40s linear infinite;    
  justify-content: center;
      /* border: 1px solid black; */

}

.sllide {
  height: 100px;
  width: 250px;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
}

.sllide img{
    /* border: 1px solid black; */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
