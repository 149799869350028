.blogCard-a {
  cursor: pointer;
}
.blogTitle-a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-bottom: 0px !important;
  font-size: x-large;
}

.blog-a a {
  color: #3366cc;
  cursor: pointer;
}
.blog-a a:hover {
  text-decoration: underline;
}

.blogIndex0 {
  min-height: fit-content;
}

.blogContent-aa {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-bottom: 0px !important;
  transition: -webkit-line-clamp 0.6s ease;
  height: 75px;
}
.blogContent-a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-bottom: 0px !important;
  transition: -webkit-line-clamp 0.6s ease;
  height: 85x;
}
#readMore-a {
  cursor: pointer;
}
.readMore {
  display: inline-block;
  cursor: pointer;
}

.pagination-a {
  /* border: 3px solid black; */
  padding-left: 70px;
}

.blogImg-a {
  aspect-ratio: 3/2;
}

.blogCategories {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 10px;
}

.blogHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.blogSearchForm {
  width: 295px;
  color: silver;
  border: 1px solid silver;
}

@media (max-width: 575px) {
  .blogHeading {
    flex-direction: column;
    align-items: start;
  }
  .blogSearchForm {
    width: 100%;
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .blogSearchForm {
    width: 360px;
  }
}

@media (min-width: 1400px) {
  .blogSearchForm {
    width: 415px;
  }
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: none;
}

.blogCategory {
  border: 1px solid #c5d4f9;
  background-color: #eff2f5;
  padding: 2px 20px;
  cursor: pointer;
  color: #1e429f;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}

/* background-color: #f4f4f4;
    padding: 4px 5px;
    cursor: pointer;
    color: #666666;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: x-small;
    width: 25%;
    border: 1px solid #cccccc2e;
    border-radius: 3px; */

.blogCategory:hover {
  background-color: hsl(211, 98%, 98%);
}

.blog-right-main {
  margin-bottom: 20px;
}

.blog-right {
  background-color: #f8f9fa;
  overflow: auto;
  border: 1px solid rgba(14, 29, 52, 0.15);
  padding: 10px;
}

@media (min-width:1024px) and (max-width:1199px) {
  .blog-right {
    height: 685px;
  }
}
@media (min-width:1199px) and (max-width:1399px) {
  .blog-right {
    height: 740px;
  }
}

@media (min-width:1399px) {
  .blog-right {
    height: 830px;
  }
}

.PopularBlogWrap {
  overflow-y: auto;
}

.PopularBlogCard {
  cursor: pointer;
}

.th-a{
  font-size: .8rem;
}

.blogCardCategory {
  border: 1px solid #c5d4f9;
  background-color: #eff2f5;
  cursor: pointer;
  padding: 5px 20px;
  color: #1e429f;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}

@media (max-width: 575px) {
  .blogCardCategory {
    border: 1px solid #c5d4f9;
    background-color: #eff2f5;
    padding: 0px 2px;
    color: #1e429f;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: x-small;
  }
}

@media (max-width:575px) {
  .th-a{
    display: flex ;
    flex-direction: column;
    gap: 10px;
  }
}



/*  */

.PopularBlogWrap .card {
  flex-direction: row;
  background-color: #696969;
  border: 1px solid rgba(14, 29, 52, 0.15);
}

.PopularBlogWrap .card.card.bg-light-subtle .card-title {
  color: dimgrey;
}

.PopularBlogWrap .card img {
  max-width: 50%;
  /* max-height: 25%; */
  margin: auto;
  padding: 0.5em;
  border-radius: 0.7em;
  object-fit: contain;
}
.PopularBlogWrap .card-body {
  display: flex;
  justify-content: space-between;
}

.text-section {
  max-width: 100%;
}

.PopularBlogWrap .card-text {
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0 !important;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

#categorySpan {
  font-size: 0.7rem;
}

@media screen and (max-width: 475px) {
  .PopularBlogWrap .card {
    font-size: 0.9em;
  }
}

.LoadMoreBlog {
  background-color: #00297c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.LoadMoreBlog:hover {
  background-color: #00297cdc;
}


.BlogCategory-a{
  font-size: 0.8rem;
}


/* links */

.blogSocialLinks{
  color: #3366cc;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}


.blogSocialLinks a{
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3366cc;
  border-radius: 100%;
  padding: 5px;
}

.blogSocialLinks a:hover{
  border: 1px solid #97b4ed;
}



.EmptyPopularData{
  padding: 10px;
  font-size: .8rem;
  color: #575555;
}

.emptyCategory{
  font-size: .7rem;
  color: #575555;
}

