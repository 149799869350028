/* .blogTitle-a{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-bottom: 0px !important;
} */

.blog-a a {
  color: #3366cc;
  cursor: pointer;
}
.blog-a a:hover {
  text-decoration: underline;
}

.blogContent-a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-bottom: 0px !important;
  transition: -webkit-line-clamp 0.6s ease;
}
#readMore-a {
  cursor: pointer;
}
.readMore {
  display: inline-block;
  cursor: pointer;
}

.pagination-a {
  /* border: 3px solid black; */
  padding-left: 70px;
}

.blogImg-a {
  aspect-ratio: 3/2;
}

/* .blogHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  } */

.blogSearchForm-c {
  width: 300px;
  color: silver;
  border: 1px solid silver;
}


@media (max-width:575px){
  .blogSearchForm-c {
    width: 100%;
  }
}
.form-control:focus {
  outline: none;
  box-shadow: none; /* Remove any additional shadow */
  border-color: none; /* Ensure no border color change */
}

.blogCategoryHeadign {
  text-transform: capitalize;
}

.input-groupSearch-c {
  display: flex;
  color: silver;
  border: 1px solid silver;
}

.input-group-append SVG {
  color: gray;
  font-size: 1.3rem;
}
